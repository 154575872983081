@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600&display=swap');

body {
  font-family: "Noto Sans", sans-serif;
}

/* Hospital Title */
.hospital-title {
  font-family: "Noto Sans", sans-serif;
  
  text-align: left !important;
  width: 55vw;
  height: 1vw;
  left: 10vw;
  top: 10.5vw ;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.02em;

  color: #000000;



}

/* Filters and Actions Container */
.filters-and-actions {
  display: flex;
  align-items: center !important;
  justify-content: space-around;
  gap: 30px;
  margin: 20px 0;
  padding: 8px 16px;
  background-color: #fff;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

/* Filters Section */
.filters {
  display: flex;
  padding-top: 1vw;
  gap: 10px;
}

/* Filters Title */
.filters-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

/* Buttons and Inputs */
.input-group select,
.filter-btn,
.add-hospital-btn {
  width: 123px;
  height: 48px;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.input-group select {
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.input-group select:hover,
.input-group select:focus,
.filter-btn:hover {
  border-color: #6D1DF0;
  box-shadow: 0px 0px 6px rgba(109, 29, 240, 0.3);
}

.add-hospital-btn {
  font-family: "Public Sans", sans-serif;
  background-color: #6D1DF0;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-hospital-btn:hover {
  background-color: #5611C9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Table */
.react-table {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  border: 1px solid rgba(224, 224, 224, 0.6);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  overflow-x: auto;
  width: 98%;
}

.react-table th {
  text-align: left;
  color: #8888AF;
  font-weight: normal;
}

.react-table th,
.react-table td {
  padding: 10px;
}

.react-table td:first-child {
  font-weight: bold;
  color: #000;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 999;
}

.modal-content {
  position: relative;
  background: white;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Input Group */
.input-group {
  position: relative;
  margin-bottom: 16px;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}

.input-group input:focus,
.input-group select:focus {
  border-color: #6D1DF0;
}

/* Error Message */
.error {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #FF0D0D;
  margin-top: 4px;
  width: 100%;
}

/* Backdrop */
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

/* Modal Actions */
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.actions button {
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.actions button:first-child {
  background-color: #ccc;
  color: black;
}

.actions button:last-child {
  background-color: #6D1DF0;
  color: white;
}

/* Success Popup */
.success-popup {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(224, 246, 230, 0.9);
  color: #228b22;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  z-index: 1000;
  animation: fadeInOut 3s ease;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Sign-Up Modal
.sign-up-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
backdrop-filter: blur(5px);
z-index: 1000;
}

.sign-up-modal-content {
background: white;
width: 450px;
padding: 24px;
border-radius: 8px;
box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
z-index: 1001;
}

.sign-up-title {
text-align: center;
font-size: 20px;
font-weight: 600;
margin-bottom: 20px;
}

.sign-up-actions {
display: flex;
justify-content: space-between;
margin-top: 20px;
}

.sign-up-actions button:first-child {
background-color: #ccc;
color: black;
}

.sign-up-actions button:last-child {
background-color: #6D1DF0;
color: white;
} */

.find-device-btn {
  font-family: "Public Sans", sans-serif;
  background-color: #6D1DF0;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 123px;
  height: 48px;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.find-device-btn:hover {
  background-color: #5611C9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
/* Modal Container */
h5 {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0px;
}
.find-device-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.3); /* Slight dark overlay */
  z-index: 999;
}

/* Modal Box */
.modal-content {
  position: relative;
  background: white;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: red;
}

/* Device Selection Buttons */
.device-selection {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.device-selection button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.device-selection button:first-child {
  background: #5611C9;
  color: white;
}

.device-selection button:last-child {
  background:#5611C9;
  color: white;
}

.device-selection button:hover {
  filter: brightness(90%);
}

/* Search Section */
.search-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.search-section input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.search-button {
  background: rgb(16, 105, 199);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-button:hover {
  background: rgb(16, 105, 199);
}

/* Error Message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
